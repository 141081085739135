<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";
import {required} from "vuelidate/lib/validators";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {paginationHelper} from "@/helpers/pagination-helper";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

/**
 * Short Codes Component
 */
export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },
  components: {
    CustomCardSubtitle,
    Layout,
    PageHeader,
    ckeditor: CKEditor.component
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false
      },

      inputPage: "",

      shortCodes: null,

      shortCode: {
        name: "",
        content: ""
      },

      visibleModal: false,
      editor: ClassicEditor
    };
  },

  validations: {
    shortCode: {
      name: { required },
      content: { required }
    }
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('short-codes.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "name", label: this.$t('short-codes.table.name') },
        { key: "content", label: this.$t('short-codes.table.content') },
        { key: "action", label: this.$t('table.actions') }
      ]
    },

    async loadShortCodes() {
      const result = await axios.get(`/short-code`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      });

      this.shortCodes = result.data
      this.table.totalRows = this.shortCodes.length;
      this.table.rows = this.shortCodes.length

      if (this.mounted) {
        this.$refs.table.refresh();
      }
    },

    deleteShortCode(shortCode) {
      this.submitted = true;

      const json = JSON.stringify({
        "id": shortCode.id
      });

      swalHelper.yesOrNo(() => {
        axios.delete(`/short-code`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: json
        }).then(() => {
          this.$bvToast.toast(this.$t('short-codes.delete.success'), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          this.shortCodes = this.shortCodes.filter(element => element.id !== shortCode.id)
          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        });
      });
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.visibleModal = false;
      this.shortCode = {
        name: "",
        content: ""
      }
    },

    editShortCode(shortCode) {
      this.shortCode = Object.assign({}, shortCode);
      this.visibleModal = true
    },

    createOrEditShortCode() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.shortCode.$touch();
      if (this.$v.shortCode.$invalid) {
        return;
      }

      const json = JSON.stringify(this.shortCode);

      axios.put(`/short-code`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then((result) => {
        this.$bvToast.toast(this.$t(this.shortCode.id ? "short-codes.edit.success" : "short-codes.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        if (this.shortCode.id) {
          for (const index in this.shortCodes) {
            if (this.shortCodes[index].id === this.shortCode.id) {
              this.shortCodes[index] = this.shortCode;
              break;
            }
          }

          this.$refs.table.refresh();
        } else {
          this.shortCodes.push(result.data)
        }

        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    }

  },

  async created() {
    try {
      await this.loadShortCodes()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('short-codes.title')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="clickable-element btn btn-success mb-2 mx-2" @click="visibleModal = true">
                  <i class="mdi mdi-plus mr-2"></i> {{ $t('short-codes.create.button') }}
                </a>
              </div>
            </div>

            <template v-if="shortCodes">
                <div class="py-4">
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          {{ $t('table.show') }}&nbsp;
                          <b-form-select v-model="table.perPage" size="sm" :options="table.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center">
                          {{ $t('table.search') }}
                          <b-form-input
                              v-model="table.filter"
                              type="search"
                              class="form-control form-control-sm ml-2"
                              @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="float-right">
                        <label class="d-inline-flex align-items-center mx-2">
                          <b-form-input v-model="inputPage" class="form-control form-control-sm ml-2" />
                        </label>
                        <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, inputPage)">{{ $t('table.go-to-page') }}</b-button>
                      </div>
                    </div>
                  </div>

                  <div class="row py-3">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <b-table
                        ref="table"
                        :items="shortCodes"
                        :fields="getFields()"
                        responsive="sm"
                        :per-page="table.perPage"
                        :current-page="table.currentPage"
                        :sort-by.sync="table.sortBy"
                        :sort-desc.sync="table.sortDesc"

                        :filter-included-fields="table.filterOn"
                        :empty-text="$t('message.no-elements')"
                        :empty-filtered-text="$t('message.no-records')" :show-empty="true"
                        @filtered="elements => paginationHelper.onFiltered(table, elements)">
                      <template v-slot:cell(content)="{ item }">
                        <div v-html="item.content" />
                      </template>

                      <template v-slot:cell(action)="{ item }">
                        <div style="width: 120px;">
                          <a @click="editShortCode(item)" class="clickable-element mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                          <a @click="deleteShortCode(item)" class="clickable-element text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a>
                        </div>
                      </template>
                    </b-table>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="float-left">
                        <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(table), 'elements': paginationHelper.getElementsCount(table), 'all': table.totalRows }) }}</p>
                      </div>

                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        size="xl"
        :scrollable="true"
        v-model="visibleModal"
        :title="$t(this.shortCode.id ? 'short-codes.edit.title' : 'short-codes.create.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>
        <div class="form-group">
          <label for="name">{{ $t('short-codes.table.name') }}</label>
          <input id="name" v-model="shortCode.name" type="text" name="title" class="form-control" :class="{ 'is-invalid': submitted && $v.shortCode.name.$error }" />
          <div v-if="submitted && !$v.shortCode.name.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="content">{{ $t('short-codes.table.content') }}</label>
          <ckeditor id="content" v-model="shortCode.content" :editor="editor" :class="{ 'is-invalid': submitted && $v.shortCode.content.$error }"></ckeditor>
          <div v-if="submitted && !$v.shortCode.content.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="text-right">
          <b-button @click="createOrEditShortCode" variant="success">{{ $t(this.shortCode.id ? 'short-codes.edit.button' : 'short-codes.create.button') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>